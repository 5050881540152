'use strict';

export const header = (() => {

    /**
     * init
     */
    const init = () => {
		if ($('.merit').length === 0) {
			$('a[href="#merit"]').parent().remove();
		}

        $('.js-hamburger').on('click', (event) => {
			const $this = $(event.currentTarget);
			$this.toggleClass('is-opened');
			$('body').toggleClass('is-opened');
        });
    };

    /**
     * sp
     */
    const sp = () => {
		const windowWidth = window.innerWidth;

		if(windowWidth < 769) {
			const $anchor = $('.js-anchor a');
			const $hamburger = $('.js-hamburger');

            $anchor.on('click', () => {
				$hamburger.removeClass('is-opened');
				$('body').removeClass('is-opened');
            });
		}
    };

    return {
        init: init,
		sp: sp
    };
})();
