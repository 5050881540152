'use strict';

import slick from 'slick-carousel';

export const carousel = (() => {

    /**
     * init
     */
    const init = () => {
        $('.js-carousel').slick({
			arrows: false,
            variableWidth: true,
			speed: 10000,
            autoplay: true,
            autoplaySpeed: 0,
			cssEase: 'linear',
        });
    }

    // public
    return {
        init: init
    };
})();