'use strict';

import 'waypoints/lib/jquery.waypoints.min';

export const inview = (() => {

    /**
     * init
     */
    const init = () => {
        $('.js-inview').each((index, element) => {
            const waypoint = new Waypoint({
                element: element,
                handler: direction => {
                    $(element).addClass('is-inview');
                },
                offset: $(element).data('offset') ? $(element).data('offset') : '90%'
            });
        });

		const headerHight = $('.layout-header').outerHeight();
		const navItems = $('.js-follower-nav');

        $('.js-follower').each((index, element) => {
            const waypoint = new Waypoint({
                element: element,
                handler: direction => {
						navItems.removeClass('is-active');
						navItems.eq(index).toggleClass('is-active');
                },
                offset: $(element).data('offset') ? $(element).data('offset') : headerHight
            });
        });

        navItems.on('click', function() {
			setTimeout(() => {
				navItems.removeClass('is-active');
				navItems.eq($(this).index()).toggleClass('is-active');
			}, 600);
        });

        $(window).on('resize', () => {
            setTimeout(() => {
                refresh();
            }, 100);
        });
    };

    /**
     * refresh
     */
    const refresh = () => {
        Waypoint.refreshAll();
    };

    return {
        init: init,
        refresh: refresh
    };
})();
